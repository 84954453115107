@font-face {
	font-family: "helios-icons";
	src: url("./helios-icons.eot?2074c7da7947d3b6ec29b03404d54411?#iefix") format("embedded-opentype"),
url("./helios-icons.woff2?2074c7da7947d3b6ec29b03404d54411") format("woff2"),
url("./helios-icons.woff?2074c7da7947d3b6ec29b03404d54411") format("woff");
}

.helios-icon {
	line-height: 1;
}

.helios-icon:before {
	font-family: helios-icons !important;
	font-style: normal;
	font-weight: normal !important;
	vertical-align: top;
}

.helios-icon-action-add-audio:before {
	content: "\f101";
}
.helios-icon-action-add-circle:before {
	content: "\f102";
}
.helios-icon-action-add-image:before {
	content: "\f103";
}
.helios-icon-action-add-mail-template:before {
	content: "\f104";
}
.helios-icon-action-add-profile:before {
	content: "\f105";
}
.helios-icon-action-add-video:before {
	content: "\f106";
}
.helios-icon-action-add:before {
	content: "\f107";
}
.helios-icon-action-bookmark:before {
	content: "\f108";
}
.helios-icon-action-cancel-review-invites-alternative:before {
	content: "\f109";
}
.helios-icon-action-cancel-review-invites:before {
	content: "\f10a";
}
.helios-icon-action-cancel-view:before {
	content: "\f10b";
}
.helios-icon-action-copy:before {
	content: "\f10c";
}
.helios-icon-action-delete-circle:before {
	content: "\f10d";
}
.helios-icon-action-delete:before {
	content: "\f10e";
}
.helios-icon-action-dislike:before {
	content: "\f10f";
}
.helios-icon-action-dismiss:before {
	content: "\f110";
}
.helios-icon-action-download:before {
	content: "\f111";
}
.helios-icon-action-duplicate:before {
	content: "\f112";
}
.helios-icon-action-edit:before {
	content: "\f113";
}
.helios-icon-action-favorite-with-heart:before {
	content: "\f114";
}
.helios-icon-action-favorite-with-star:before {
	content: "\f115";
}
.helios-icon-action-filter-alternative:before {
	content: "\f116";
}
.helios-icon-action-filter-circle:before {
	content: "\f117";
}
.helios-icon-action-filter:before {
	content: "\f118";
}
.helios-icon-action-hamburger-menu:before {
	content: "\f119";
}
.helios-icon-action-launch:before {
	content: "\f11a";
}
.helios-icon-action-like:before {
	content: "\f11b";
}
.helios-icon-action-mark-as-spam:before {
	content: "\f11c";
}
.helios-icon-action-more-vertical:before {
	content: "\f11d";
}
.helios-icon-action-more:before {
	content: "\f11e";
}
.helios-icon-action-new-document:before {
	content: "\f11f";
}
.helios-icon-action-open-in-new-tab:before {
	content: "\f120";
}
.helios-icon-action-pin:before {
	content: "\f121";
}
.helios-icon-action-preview:before {
	content: "\f122";
}
.helios-icon-action-remove-bookmark:before {
	content: "\f123";
}
.helios-icon-action-remove:before {
	content: "\f124";
}
.helios-icon-action-reply:before {
	content: "\f125";
}
.helios-icon-action-report:before {
	content: "\f126";
}
.helios-icon-action-search:before {
	content: "\f127";
}
.helios-icon-action-select-all:before {
	content: "\f128";
}
.helios-icon-action-select-date:before {
	content: "\f129";
}
.helios-icon-action-select-none:before {
	content: "\f12a";
}
.helios-icon-action-select-some:before {
	content: "\f12b";
}
.helios-icon-action-send:before {
	content: "\f12c";
}
.helios-icon-action-share-android:before {
	content: "\f12d";
}
.helios-icon-action-share-ios:before {
	content: "\f12e";
}
.helios-icon-action-share:before {
	content: "\f12f";
}
.helios-icon-action-signout-alternative:before {
	content: "\f130";
}
.helios-icon-action-signout:before {
	content: "\f131";
}
.helios-icon-action-skip:before {
	content: "\f132";
}
.helios-icon-action-sorting:before {
	content: "\f133";
}
.helios-icon-action-unpin:before {
	content: "\f134";
}
.helios-icon-action-upload:before {
	content: "\f135";
}
.helios-icon-action-view:before {
	content: "\f136";
}
.helios-icon-actions-duplicate:before {
	content: "\f137";
}
.helios-icon-actions-mark-all-as-read:before {
	content: "\f138";
}
.helios-icon-arrow-caret-down:before {
	content: "\f139";
}
.helios-icon-arrow-caret-left:before {
	content: "\f13a";
}
.helios-icon-arrow-caret-right:before {
	content: "\f13b";
}
.helios-icon-arrow-caret-up:before {
	content: "\f13c";
}
.helios-icon-arrow-chevron-down:before {
	content: "\f13d";
}
.helios-icon-arrow-chevron-left-double:before {
	content: "\f13e";
}
.helios-icon-arrow-chevron-left:before {
	content: "\f13f";
}
.helios-icon-arrow-chevron-right-double:before {
	content: "\f140";
}
.helios-icon-arrow-chevron-right:before {
	content: "\f141";
}
.helios-icon-arrow-chevron-up:before {
	content: "\f142";
}
.helios-icon-arrow-cursor:before {
	content: "\f143";
}
.helios-icon-arrow-left:before {
	content: "\f144";
}
.helios-icon-arrow-north-east:before {
	content: "\f145";
}
.helios-icon-arrow-refresh:before {
	content: "\f146";
}
.helios-icon-arrow-right:before {
	content: "\f147";
}
.helios-icon-arrow-source:before {
	content: "\f148";
}
.helios-icon-arrow-south-east:before {
	content: "\f149";
}
.helios-icon-b2c-editorial-office:before {
	content: "\f14a";
}
.helios-icon-b2c-magazin-and-articles:before {
	content: "\f14b";
}
.helios-icon-b2c-themenwelten:before {
	content: "\f14c";
}
.helios-icon-channel-category-automotive:before {
	content: "\f14d";
}
.helios-icon-channel-category-baby:before {
	content: "\f14e";
}
.helios-icon-channel-category-barcode:before {
	content: "\f14f";
}
.helios-icon-channel-category-bath:before {
	content: "\f150";
}
.helios-icon-channel-category-bed:before {
	content: "\f151";
}
.helios-icon-channel-category-books:before {
	content: "\f152";
}
.helios-icon-channel-category-carnival:before {
	content: "\f153";
}
.helios-icon-channel-category-clothing:before {
	content: "\f154";
}
.helios-icon-channel-category-consulting:before {
	content: "\f155";
}
.helios-icon-channel-category-consumer-electronics:before {
	content: "\f156";
}
.helios-icon-channel-category-cosmetics-and-body-care:before {
	content: "\f157";
}
.helios-icon-channel-category-customer-care:before {
	content: "\f158";
}
.helios-icon-channel-category-erotic:before {
	content: "\f159";
}
.helios-icon-channel-category-fashion:before {
	content: "\f15a";
}
.helios-icon-channel-category-finance:before {
	content: "\f15b";
}
.helios-icon-channel-category-floristics:before {
	content: "\f15c";
}
.helios-icon-channel-category-food:before {
	content: "\f15d";
}
.helios-icon-channel-category-gardening:before {
	content: "\f15e";
}
.helios-icon-channel-category-glasses:before {
	content: "\f15f";
}
.helios-icon-channel-category-gold:before {
	content: "\f160";
}
.helios-icon-channel-category-hardware-store:before {
	content: "\f161";
}
.helios-icon-channel-category-hobby:before {
	content: "\f162";
}
.helios-icon-channel-category-household-appliances:before {
	content: "\f163";
}
.helios-icon-channel-category-lighting:before {
	content: "\f164";
}
.helios-icon-channel-category-living:before {
	content: "\f165";
}
.helios-icon-channel-category-luxury-food-no spacing:before {
	content: "\f166";
}
.helios-icon-channel-category-luxury-food:before {
	content: "\f167";
}
.helios-icon-channel-category-medicine:before {
	content: "\f168";
}
.helios-icon-channel-category-music:before {
	content: "\f169";
}
.helios-icon-channel-category-petsupplies:before {
	content: "\f16a";
}
.helios-icon-channel-category-presents:before {
	content: "\f16b";
}
.helios-icon-channel-category-product:before {
	content: "\f16c";
}
.helios-icon-channel-category-server:before {
	content: "\f16d";
}
.helios-icon-channel-category-shoes:before {
	content: "\f16e";
}
.helios-icon-channel-category-shop:before {
	content: "\f16f";
}
.helios-icon-channel-category-shopping:before {
	content: "\f170";
}
.helios-icon-channel-category-sports-and-outdoor:before {
	content: "\f171";
}
.helios-icon-channel-category-suitcase:before {
	content: "\f172";
}
.helios-icon-channel-category-tickets:before {
	content: "\f173";
}
.helios-icon-channel-category-travel:before {
	content: "\f174";
}
.helios-icon-channel-category-watches-and-jewelry:before {
	content: "\f175";
}
.helios-icon-device-desktop-browser:before {
	content: "\f176";
}
.helios-icon-device-laptop:before {
	content: "\f177";
}
.helios-icon-device-smartphone:before {
	content: "\f178";
}
.helios-icon-editor-align-center:before {
	content: "\f179";
}
.helios-icon-editor-align-justify:before {
	content: "\f17a";
}
.helios-icon-editor-align-left:before {
	content: "\f17b";
}
.helios-icon-editor-align-right:before {
	content: "\f17c";
}
.helios-icon-editor-bold:before {
	content: "\f17d";
}
.helios-icon-editor-chart-donut:before {
	content: "\f17e";
}
.helios-icon-editor-chart:before {
	content: "\f17f";
}
.helios-icon-editor-cut:before {
	content: "\f180";
}
.helios-icon-editor-duplicate:before {
	content: "\f181";
}
.helios-icon-editor-emoji-filled:before {
	content: "\f182";
}
.helios-icon-editor-emoji-sad:before {
	content: "\f183";
}
.helios-icon-editor-emoji:before {
	content: "\f184";
}
.helios-icon-editor-hashtag:before {
	content: "\f185";
}
.helios-icon-editor-italic:before {
	content: "\f186";
}
.helios-icon-editor-link:before {
	content: "\f187";
}
.helios-icon-editor-list-ordered:before {
	content: "\f188";
}
.helios-icon-editor-list-unordered:before {
	content: "\f189";
}
.helios-icon-editor-page-break:before {
	content: "\f18a";
}
.helios-icon-editor-paste:before {
	content: "\f18b";
}
.helios-icon-editor-redo:before {
	content: "\f18c";
}
.helios-icon-editor-short-text:before {
	content: "\f18d";
}
.helios-icon-editor-undo:before {
	content: "\f18e";
}
.helios-icon-editor-yes-no:before {
	content: "\f18f";
}
.helios-icon-feature-navigation-analytics:before {
	content: "\f190";
}
.helios-icon-feature-navigation-app-switcher:before {
	content: "\f191";
}
.helios-icon-feature-navigation-automation:before {
	content: "\f192";
}
.helios-icon-feature-navigation-browser-extension:before {
	content: "\f193";
}
.helios-icon-feature-navigation-channel:before {
	content: "\f194";
}
.helios-icon-feature-navigation-code:before {
	content: "\f195";
}
.helios-icon-feature-navigation-contact:before {
	content: "\f196";
}
.helios-icon-feature-navigation-dashboard:before {
	content: "\f197";
}
.helios-icon-feature-navigation-email-template:before {
	content: "\f198";
}
.helios-icon-feature-navigation-helpcenter:before {
	content: "\f199";
}
.helios-icon-feature-navigation-home:before {
	content: "\f19a";
}
.helios-icon-feature-navigation-integrations-alternative:before {
	content: "\f19b";
}
.helios-icon-feature-navigation-integrations:before {
	content: "\f19c";
}
.helios-icon-feature-navigation-marketing:before {
	content: "\f19d";
}
.helios-icon-feature-navigation-message-templates:before {
	content: "\f19e";
}
.helios-icon-feature-navigation-notifications:before {
	content: "\f19f";
}
.helios-icon-feature-navigation-questionnaire-template:before {
	content: "\f1a0";
}
.helios-icon-feature-navigation-review-inbox:before {
	content: "\f1a1";
}
.helios-icon-feature-navigation-review-invites-clean:before {
	content: "\f1a2";
}
.helios-icon-feature-navigation-review-invites:before {
	content: "\f1a3";
}
.helios-icon-feature-navigation-seo-profile:before {
	content: "\f1a4";
}
.helios-icon-feature-navigation-settings-alternative:before {
	content: "\f1a5";
}
.helios-icon-feature-navigation-settings:before {
	content: "\f1a6";
}
.helios-icon-feature-navigation-support-outline:before {
	content: "\f1a7";
}
.helios-icon-feature-navigation-support:before {
	content: "\f1a8";
}
.helios-icon-feature-navigation-templates:before {
	content: "\f1a9";
}
.helios-icon-feature-navigation-touchpoints:before {
	content: "\f1aa";
}
.helios-icon-feedback-cancel-circle:before {
	content: "\f1ab";
}
.helios-icon-feedback-check-circle:before {
	content: "\f1ac";
}
.helios-icon-feedback-check:before {
	content: "\f1ad";
}
.helios-icon-feedback-email-alternative:before {
	content: "\f1ae";
}
.helios-icon-feedback-info-circle-outline:before {
	content: "\f1af";
}
.helios-icon-feedback-info-circle:before {
	content: "\f1b0";
}
.helios-icon-feedback-info:before {
	content: "\f1b1";
}
.helios-icon-feedback-stop:before {
	content: "\f1b2";
}
.helios-icon-feedback-verified:before {
	content: "\f1b3";
}
.helios-icon-feedback-warning-circle-outlined:before {
	content: "\f1b4";
}
.helios-icon-feedback-warning-circle:before {
	content: "\f1b5";
}
.helios-icon-feedback-warning-sign:before {
	content: "\f1b6";
}
.helios-icon-feedback-warning:before {
	content: "\f1b7";
}
.helios-icon-label-attachment:before {
	content: "\f1b8";
}
.helios-icon-label-b2b:before {
	content: "\f1b9";
}
.helios-icon-label-b2c:before {
	content: "\f1ba";
}
.helios-icon-label-bicycle:before {
	content: "\f1bb";
}
.helios-icon-label-calendar:before {
	content: "\f1bc";
}
.helios-icon-label-cloud-outline:before {
	content: "\f1bd";
}
.helios-icon-label-cloud:before {
	content: "\f1be";
}
.helios-icon-label-covid:before {
	content: "\f1bf";
}
.helios-icon-label-credit-card:before {
	content: "\f1c0";
}
.helios-icon-label-email:before {
	content: "\f1c1";
}
.helios-icon-label-event:before {
	content: "\f1c2";
}
.helios-icon-label-expired:before {
	content: "\f1c3";
}
.helios-icon-label-graduate:before {
	content: "\f1c4";
}
.helios-icon-label-health-outline:before {
	content: "\f1c5";
}
.helios-icon-label-health:before {
	content: "\f1c6";
}
.helios-icon-label-idea:before {
	content: "\f1c7";
}
.helios-icon-label-in-moderation:before {
	content: "\f1c8";
}
.helios-icon-label-link:before {
	content: "\f1c9";
}
.helios-icon-label-location-pin:before {
	content: "\f1ca";
}
.helios-icon-label-message-multiple:before {
	content: "\f1cb";
}
.helios-icon-label-message:before {
	content: "\f1cc";
}
.helios-icon-label-password:before {
	content: "\f1cd";
}
.helios-icon-label-pending:before {
	content: "\f1ce";
}
.helios-icon-label-phone-call:before {
	content: "\f1cf";
}
.helios-icon-label-qr-code:before {
	content: "\f1d0";
}
.helios-icon-label-receipt:before {
	content: "\f1d1";
}
.helios-icon-label-reminder:before {
	content: "\f1d2";
}
.helios-icon-label-secure:before {
	content: "\f1d3";
}
.helios-icon-label-tag:before {
	content: "\f1d4";
}
.helios-icon-label-time:before {
	content: "\f1d5";
}
.helios-icon-label-touchpoint:before {
	content: "\f1d6";
}
.helios-icon-label-trophy:before {
	content: "\f1d7";
}
.helios-icon-label-website-url:before {
	content: "\f1d8";
}
.helios-icon-media-image:before {
	content: "\f1d9";
}
.helios-icon-service-chrome:before {
	content: "\f1da";
}
.helios-icon-service-facebook:before {
	content: "\f1db";
}
.helios-icon-service-firefox:before {
	content: "\f1dc";
}
.helios-icon-service-google:before {
	content: "\f1dd";
}
.helios-icon-service-instagram:before {
	content: "\f1de";
}
.helios-icon-service-trustpilot:before {
	content: "\f1df";
}
.helios-icon-service-twitter:before {
	content: "\f1e0";
}
.helios-icon-service-youtube:before {
	content: "\f1e1";
}
.helios-icon-trusted-shops-product-analytics:before {
	content: "\f1e2";
}
.helios-icon-trusted-shops-product-buyer-protection:before {
	content: "\f1e3";
}
.helios-icon-trusted-shops-product-certification:before {
	content: "\f1e4";
}
.helios-icon-trusted-shops-product-etrusted:before {
	content: "\f1e5";
}
.helios-icon-trusted-shops-product-legal-services:before {
	content: "\f1e6";
}
.helios-icon-trusted-shops-product-reputation-manager:before {
	content: "\f1e7";
}
.helios-icon-trusted-shops-product-reviews:before {
	content: "\f1e8";
}
.helios-icon-user-assign:before {
	content: "\f1e9";
}
.helios-icon-user-female:before {
	content: "\f1ea";
}
.helios-icon-user-group:before {
	content: "\f1eb";
}
.helios-icon-user-id-name-tag:before {
	content: "\f1ec";
}
.helios-icon-user-male-alternative:before {
	content: "\f1ed";
}
.helios-icon-user-male:before {
	content: "\f1ee";
}
.helios-icon-user-user-circle:before {
	content: "\f1ef";
}
